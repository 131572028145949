import * as React from "react";
import { MobXProviderContext, observer } from "mobx-react";
import { updateSearch } from "bernie-core";
import { useLocation, useHistory } from "react-router-dom";

import { EGDSTab, EGDSTabs, EGDSTabsType } from "@egds/react-core/tabs";
import { UitkTabsType as EGDSTabsTypeExperimental } from "uitk-react-experimental-button-tabs";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";

import { useLocalization } from "@shared-ui/localization-context";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

import { withStores } from "stores";
import { ExternalLinkTabConfig, Navigation } from "stores/wizard/config";
import { ClientWizardStateStore } from "stores/wizard/ClientWizardStateStore";
import { FlexModuleModelStore } from "stores/FlexModuleModelStore";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { TabbedWizard } from "./TabbedWizard";
import { getGTLabelTokenFromState, getLobConfigFromState } from "./ConfigHelpers/LobsConfig";
import { getExternalLinkTabConfig, getExternalLinkTabURL, isExtLinkTab } from "./ConfigHelpers/ExtLinkConfig";
import { LOB_KEYS, wizardLobQueryParamKey } from "../constants";
import { isVariantEnabled } from "components/utility/experiment";
import { triggerFlightsCachingWarming } from "src/utils/FlightsCacheWarmingUtil";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";
import { RegionChildrenList } from "src/utils/RegionUtils";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";
import { StorefrontWizardRegionBEXTemplateComponent } from "src/components/flexComponents/WizardPWARegions/StorefrontWizardRegionBEX/typings";

/**
 * @property lobForms: a list of React elements representing each LOB form added in the flex manager
 */
export interface LobFormWithTabsProps {
  wizardFormClassNames?: string;
  wizardFormSpacing?: EGDSSpacingProps;
  tabGroupClassNames?: string;
  trackOnChangeTab?: (tabName: string, rfrrId?: string) => void;
  context?: ExtendedContextStore;
  flexModuleModelStore: FlexModuleModelStore;
  tabsType?: EGDSTabsType | EGDSTabsTypeExperimental;
  templateComponents: ExtendedTemplateComponent[];
  blossomComponent: BlossomComponent;
}

export const LobFormWithTabs = withStores("flexModuleModelStore")(
  observer((props: LobFormWithTabsProps) => {
    const {
      wizardFormClassNames,
      wizardFormSpacing,
      tabGroupClassNames,
      trackOnChangeTab,
      context,
      flexModuleModelStore,
      tabsType = EGDSTabsType.NATURAL,
      templateComponents,
      blossomComponent,
    } = props;
    const keyHelperWizard = new ItemKeyHelper("storefrontForms");
    const keyHelperNavigation = new ItemKeyHelper("storefrontWizardNavigation");
    const wizardState: ClientWizardStateStore = React.useContext(MobXProviderContext).wizardState;
    const [isModuleModelStoreInitialized, setModuleModelStoreInitialized] = React.useState(false);
    const [flightsTabClicked, setFlightsTabClicked] = React.useState(false);
    const defaultLobNavConfig: Navigation = {
      tabIcon: "help",
      tabTitleToken: "",
    };
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const { formatText } = useLocalization();

    /*
     * Temporary workaround to initialize wizard modules on server side rendering
     * from the flexModuleModelStore in the modelMap Map property.
     * We can remove this when the flex template renderer is refactored 100% in blossom.
     * Details of this implementation on this confluence page:
     * https://confluence.expedia.biz/display/POS/Flex+template+renderer+react+refactor+under+Wizard.
     */
    if (!isModuleModelStoreInitialized) {
      flexModuleModelStore.getModel("wizard-flight-pwa-1");
      flexModuleModelStore.getModel("wizard-hotel-pwa-v2-1");
      flexModuleModelStore.getModel("wizard-cruise-pwa-1");
      flexModuleModelStore.getModel("wizard-package-pwa-1");
      flexModuleModelStore.getModel("wizard-thirdPartyPackage-pwa-1");
      flexModuleModelStore.getModel("wizard-car-pwa-1");
      flexModuleModelStore.getModel("wizard-gt-pwa-1");
      flexModuleModelStore.getModel("wizard-vr-pwa-1");
      flexModuleModelStore.getModel("wizard-lx-pwa-1");
      flexModuleModelStore.getModel("wizard-hotel-pwa-1");
      flexModuleModelStore.getModel("wizard-externalLinkTab-pwa-1");
      setModuleModelStoreInitialized(true);
    }

    //returns the localized string given the name of the flex component used
    const getLabel = (lobName: string): string => {
      let label = defaultLobNavConfig.tabTitleToken;
      const lobStateConfig = getLobConfigFromState(lobName, wizardState);
      if (lobStateConfig) {
        label = formatText(lobStateConfig?.navigation?.tabTitleToken);
      }
      if (LOB_KEYS.GT === lobName) {
        label = formatText(getGTLabelTokenFromState(wizardState));
      }

      return label;
    };

    const defaultTabIndex = templateComponents.map((lob) => lob.type).indexOf(wizardState.activeLob);

    const [currentTabIndexLob, setCurrentTabIndexLob] = React.useState(defaultTabIndex);

    /**
     * Check the route store, if there is no LOB query attached, get it from the wizardState default and redirect
     * CurrentLOB and defaultLob set server side
     * To ensure up to date values on client side render, trigger an effect that does not have any dependent values to only run on first render
     */
    React.useEffect(() => {
      if (!queryParams.get("pwaLob")) {
        const { useDefaultLOB, defaultLob: pwaLob } = wizardState;
        if (useDefaultLOB) {
          wizardState.setUseDefaultLOB(false);
          const index = templateComponents.map((lob) => lob.type).indexOf(pwaLob);
          setCurrentTabIndexLob(index);
        }
      }
    }, []);

    /**
     * Map localized form label to flex component name
     */
    const LobFormNames: { [label: string]: string } = {};

    templateComponents.map((lob) => {
      const name = lob.type || "";
      const id = lob.metadata?.id;
      if (isExtLinkTab(name) && id) {
        LobFormNames[id] = name;
      } else {
        const formLabel = getLabel(name);
        LobFormNames[formLabel] = name;
      }
    });

    const getFormNameFromLabel = (label: string): string => {
      return LobFormNames[label];
    };

    const cleanupPwaLobQueryParam = () => {
      updateSearch({ history, location, newParams: { pwaLob: "" } });
    };

    const externalLinkTabs = templateComponents.filter((lob) => lob?.metadata?.id?.includes(LOB_KEYS.EXT_LINK));

    const onTabSelect = (tabIndexSelected: number) => {
      if (queryParams.get("pwaLob")) {
        cleanupPwaLobQueryParam();
      }

      const name = templateComponents[tabIndexSelected].type || "";
      const id = templateComponents[tabIndexSelected].metadata?.id;

      const tabNameSelected = getLabel(name);
      const formName = getFormNameFromLabel(tabNameSelected) || getFormNameFromLabel(id);
      if (
        LOB_KEYS.FLIGHT === formName &&
        !flightsTabClicked &&
        isVariantEnabled(context, "Air_Search_Performance_V2_Web_FSR_Prefetch_Recent_Searches_MVP_Test", 1)
      ) {
        setFlightsTabClicked(true);
        triggerFlightsCachingWarming(context);
      }
      if (!isExtLinkTab(formName)) {
        setGlobalValuesOnTabChange(formName);
        setCurrentTabIndexLob(tabIndexSelected);
      } else {
        redirectTo(formName, id);
      }
    };

    /**
     * Track (if available) and set wizard state
     */
    const setGlobalValuesOnTabChange = (formName: string) => {
      if (trackOnChangeTab) {
        trackOnChangeTab(formName);
      }
      wizardState.setActiveLob(formName);
      resetErrorsState();
    };

    /**
     * Redirect to another page when tab is used as link
     */
    const redirectTo = (formName: string, tabNameSelected: string) => {
      const currentLob = externalLinkTabs.find(
        (lob) => lob.metadata.id === tabNameSelected
      ) as StorefrontWizardRegionBEXTemplateComponent;
      const rfrrId = currentLob?.config?.rfrrId;
      const externalLinkTabURL = getExternalLinkTabURL(formName, context, null, currentLob, wizardState);
      if (externalLinkTabURL) {
        window.open(externalLinkTabURL);
      }
      if (trackOnChangeTab) {
        trackOnChangeTab(formName, rfrrId);
      }
    };

    const resetErrorsState = () => {
      wizardState.carWizardState.resetSubLobState();
      wizardState.carWizardState.resetSubLobTabs();
      wizardState.carWizardState.cleanErrorState();

      wizardState.lxWizardState.cleanErrorState();
      wizardState.hotelWizardState.cleanErrorState();
      wizardState.flightWizardState.cleanErrorState();
      wizardState.packageWizardState.cleanErrorState();
      wizardState.cruiseWizardState.cleanErrorState();
    };

    const formChildren = React.useMemo(
      () =>
        templateComponents.map((tc) => (
          <RegionChildrenList
            templateComponents={[tc]}
            blossomComponent={blossomComponent}
            key={`lobform-${tc.metadata.id}`}
          />
        )),
      [templateComponents]
    );

    const tabsContent = templateComponents.map((lob, index) => {
      const name = lob.type || "";
      const id = lob.metadata?.id;

      const label = getLabel(name);
      const defaultTabProps = {
        "data-testid": label,
        name: label,
        targetURI: `?${wizardLobQueryParamKey}=${name}`,
        id: name,
        tabLinkAttr: { rel: "nofollow" },
      };

      if (isExtLinkTab(name)) {
        // wizard-externalLinkTab-pwa module only
        // renders only a tab without any content or form
        const externalLinkConfig: ExternalLinkTabConfig | null = getExternalLinkTabConfig(lob, wizardState);
        const { tabTitleToken } = externalLinkConfig?.navigation || {
          tabTitleToken: null,
        };
        const extLinkTabProps = {
          ...defaultTabProps,
          name: tabTitleToken ? formatText(tabTitleToken) : "",
          targetURI: getExternalLinkTabURL(name, context, externalLinkConfig) || undefined,
          id,
        };
        return (
          <EGDSTab
            {...extLinkTabProps}
            key={keyHelperNavigation.next()}
            tabLinkAttr={{ "aria-label": `${extLinkTabProps.name}, ${formatText("opens.in.new.window")}` }}
          />
        );
      }

      return (
        <EGDSTab {...defaultTabProps} key={keyHelperNavigation.next()}>
          {currentTabIndexLob === index && (
            <EGDSSpacing {...wizardFormSpacing}>
              <div className={wizardFormClassNames || ""}>
                <TabbedWizard key={keyHelperWizard.next()}>{formChildren[index]}</TabbedWizard>
              </div>
            </EGDSSpacing>
          )}
        </EGDSTab>
      );
    });

    const tabsProps = {
      className: `lobNavigationFormWithTabs ${tabGroupClassNames || ""}`,
      "data-testid": "lobNavigationFormWithTabs",
    };

    return (
      <Viewport>
        <ViewSmall>
          <EGDSSpacing margin={{ blockstart: "two" }}>
            <EGDSTabs
              {...tabsProps}
              onTabSelect={onTabSelect}
              selectedTab={currentTabIndexLob}
              tabsType={tabsType as EGDSTabsType}
            >
              {tabsContent}
            </EGDSTabs>
          </EGDSSpacing>
        </ViewSmall>
        <ViewMedium>
          <EGDSSpacing margin={{ blockstart: "three" }}>
            <EGDSTabs
              {...tabsProps}
              onTabSelect={onTabSelect}
              centerAlign
              selectedTab={currentTabIndexLob}
              tabsType={tabsType as EGDSTabsType}
            >
              {tabsContent}
            </EGDSTabs>
          </EGDSSpacing>
        </ViewMedium>
      </Viewport>
    );
  })
);
