/**
 * Submit button should be isolated from other form elements based on UI design
 * It's might needed to be aligned left/center/right, but still in it's own row
 *
 * @example
 * <SubmitRow lobState={carLobState} />
 */
import * as React from "react";
import {
  EGDSLayoutGrid,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { EGDSIconProps } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";
import {
  StorefrontWizardRegionTypesContext,
  StorefrontWizardRegionTypes,
} from "components/flexComponents/PageLevelProviders/WizardContextProvider/StorefrontWizardRegionTypesContext";
import { OffsetGridItem } from "components/shared/OffsetGridItem/OffsetGridItem";
import { LOBState } from "src/stores/wizard/state";
import { SubmitButton } from "src/components/shared/SubmitButton/SubmitButton";
import { getShoppingGlobalMicroMessage } from "components/shared/StorefrontWizard/SubmitRow/UISShoppingGlobal";
import { observer } from "mobx-react";
import { GlobalConfig } from "stores/wizard/config";

export interface SubmitRowProps {
  lobState: LOBState;
  rfrr?: string;
  rfrrid?: string;
  globalConfig?: GlobalConfig;
  disabled?: boolean;
  hasStickyWizard?: boolean;
  testId?: string;
  extraUISTracking?: any;
  isCenteredButton?: boolean;
  submitButtonIconName?: EGDSIconProps["name"];
}

export const SubmitRow: React.FunctionComponent<SubmitRowProps> = observer(
  ({
    lobState,
    rfrr,
    globalConfig,
    disabled = false,
    hasStickyWizard,
    testId,
    extraUISTracking,
    isCenteredButton,
    submitButtonIconName,
  }) => {
    const isHalfWizard = globalConfig?.halfWizard;
    const submitGridcolumns: EGDSLayoutConditionalGridTrack = { small: 1, medium: 8, large: 12 };
    const offsetColumn: EGDSLayoutConditionalGridSpan = { medium: 3, large: isHalfWizard ? 3 : 5 };
    const submitColSpan: EGDSLayoutConditionalGridSpan = {
      small: 1,
      medium: isHalfWizard ? 8 : 2,
      large: hasStickyWizard ? 1 : isHalfWizard ? 6 : 2,
    };

    const wizardRegionContext = React.useContext(StorefrontWizardRegionTypesContext);
    const { STOREFRONT_WIZARD_REGION_BEX, STOREFRONT_WIZARD_REGION_COMET } = StorefrontWizardRegionTypes;
    const isStorefrontBEX = STOREFRONT_WIZARD_REGION_BEX === wizardRegionContext;
    const isStorefrontCOMET = STOREFRONT_WIZARD_REGION_COMET === wizardRegionContext;
    const centerSubmitButton = (isCenteredButton ?? true) && (isStorefrontBEX || isStorefrontCOMET);

    const uisShoppingGlobal = getShoppingGlobalMicroMessage(lobState);

    if (extraUISTracking) {
      uisShoppingGlobal.push(extraUISTracking);
    }

    if (hasStickyWizard) {
      return (
        <StickyWizardSubmitButton
          lobState={lobState}
          submitColSpan={submitColSpan}
          rfrr={rfrr}
          uisShoppingGlobal={uisShoppingGlobal}
          globalConfig={globalConfig}
          disabled={disabled}
          testId={testId}
        />
      );
    }

    return (
      <EGDSSpacing padding={{ small: { blockstart: "three", blockend: "six" }, medium: { blockstart: "three" } }}>
        <EGDSLayoutGrid columns={submitGridcolumns} space="three">
          {centerSubmitButton && <OffsetGridItem colSpan={offsetColumn} />}

          <SubmitButton
            lobState={lobState}
            colSpan={submitColSpan}
            rfrr={rfrr}
            uisPrimeMicroMessages={uisShoppingGlobal}
            globalConfig={globalConfig}
            disabled={disabled}
            testId={testId}
            iconName={submitButtonIconName}
          />
        </EGDSLayoutGrid>
      </EGDSSpacing>
    );
  }
);

interface StickyWizardSubmitButtonProps
  extends Pick<SubmitRowProps, "lobState" | "rfrr" | "globalConfig" | "disabled" | "testId"> {
  submitColSpan: EGDSLayoutConditionalGridSpan;
  uisShoppingGlobal: any;
}

const StickyWizardSubmitButton: React.FC<StickyWizardSubmitButtonProps> = ({
  lobState,
  rfrr,
  globalConfig,
  disabled,
  submitColSpan,
  uisShoppingGlobal,
  testId,
}) => (
  <SubmitButton
    lobState={lobState}
    colSpan={submitColSpan}
    rfrr={rfrr}
    uisPrimeMicroMessages={uisShoppingGlobal}
    globalConfig={globalConfig}
    disabled={disabled}
    testId={testId}
  />
);
